@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 300;
  src: url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-light.woff2')
      format('woff2'),
    url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-light.woff')
      format('woff');
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 400;
  src: url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-regular.woff2')
      format('woff2'),
    url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-regular.woff')
      format('woff');
}

@font-face {
  font-family: 'Moderat';
  font-style: italic;
  font-weight: 400;
  src: url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-regular-italic.woff2')
      format('woff2'),
    url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-regular-italic.woff')
      format('woff');
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 700;
  src: url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-bold.woff2')
      format('woff2'),
    url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-bold.woff')
      format('woff');
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 900;
  src: url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-bold.woff2')
      format('woff2'),
    url('https://d2kr0ibzcgqnft.cloudfront.net/fonts/moderat-bold.woff')
      format('woff');
}
