.ui {
  .modal {
    .header {
      border-bottom: 0;
    }

    .close.icon {
      &:before {
        cursor: pointer;
        position: absolute;
        font-size: 1.5em;
        color: #9a9a9a;
        left: -40px;
        top: 50px;
      }
    }
  }

  .stacked.segment {
    &::after {
      border-top: 0;
      background: none;
    }
  }

  .card {
    &.add-new {
      border-style: dashed;
      border-color: #ccc;
      color: #ccc;
      box-shadow: 0 0;
      text-align: center;
      cursor: pointer;

      &:hover {        
        border-color: #999;
        color: #999;
      }
    }
  }
}
