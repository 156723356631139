// Moxit Color system
$white: #fff !default;
$dark-gray: #333 !default;
$gray: rgb(119, 119, 119) !default;
$light-gray: rgb(241, 241, 241) !default;
$black: rgb(0, 0, 0) !default;
$blue: rgb(46, 139, 210) !default;
// $dark-blue: rgb(40, 50, 90) !default;
$dark-blue: rgb(46, 139, 210) !default;
$yellow: #ffbd33 !default;
$red: #f9461c !default;
$green: #00a91c !default;

$primary: #28325a !default;
$secondary: rgb(247, 247, 247) !default;

$default_font: Moderat, 'Helvetica Neue', Helvetica, Arial, sans-serif;
