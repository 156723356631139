html {
  line-height: 1.15;
  font-size: 100%;
}

html,
body {
  // background: $secondary;
  background: $white;
}

body {
  font-family: $default_font !important;
  color: rgba(0, 0, 0, 1);
  // font-size: 16px !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $default_font !important;
  font-weight: 600 !important;
}

p {
  // font-size: 16px;
  line-height: 24px !important;
  font-weight: 400 !important;
}

input {
  border: 1px solid #c4c8d8;
}

input,
button {
  font-family: $default_font !important;
  // font-weight: bold;
}

input {
  color: rgb(203, 203, 203);
  background-color: rgb(255, 255, 255);
  padding: 0px 16px;
  font-size: 15px;
  border: 1px solid rgb(203, 203, 203);
  border-radius: 3px;
}

.bootstrap-iso a,
.bootstrap-iso a:hover {
  color: $blue !important;
}

.ui.segment,
.ui.segments .segment {
  border-radius: 8px;
  // font-size: 16px;
}

.no-border {
  border: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.no-weight {
  font-weight: normal !important;
}

.no-top-padding {
  padding-top: 0px !important;
}

.no-bottom-padding {
  padding-bottom: 0px !important;
}

.top-border {
  border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.4);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-smaller {
  font-size: 0.9em;
}

.full-width {
  width: 100%;
}

.hide {
  display: none !important;
}

.padding-top {
  padding-top: 1em !important;
}

.padding-bottom {
  padding-bottom: 1em !important;
}

.margin {
  &.top {
    margin-top: 1em !important;
  }

  &.bottom {
    margin-bottom: 1em !important;
  }

  &.left {
    margin-left: 1em !important;
  }

  &.right {
    margin-right: 1em !important;
  }
}

.padding {
  &.top {
    padding-top: 1em !important;
  }

  &.bottom {
    padding-bottom: 1em !important;
  }

  &.left {
    padding-left: 1em !important;
  }

  &.right {
    padding-right: 1em !important;
  }
}

.margin-top {
  margin-top: 1em !important;
}

.margin-bottom {
  margin-bottom: 1em !important;
}

.margin-right {
  margin-bottom: 1em !important;
}

#page-title {
  margin: 1em 0;
}

.ui.header,
.header {
  font-family: $default_font !important;
}

.pushable {
  & > .pusher {
    display: flex;
    flex-direction: column;
    min-height: 100vh !important;
    overflow-y: auto !important;

    &:after {
      background-color: rgba(0, 0, 0, 0.65);
      z-index: 101;
    }

    #content {
      flex: 1;
      margin-top: 6em;
    }

    &.mobile-container {
      min-height: 100vh;
      background: $white !important;

      .navbar {
        background: $white;
      }

      #content {
        margin-top: 1em;
      }

      .ui.secondary.menu .item {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  // & > .pusher:after {
  //   background-color: rgba(0, 0, 0, 0.65);
  //   z-index: 101;
  // }
}

.mobile-container {
  min-height: 100vh;
  background: $white !important;

  .navbar {
    background: $white;
  }

  #content {
    margin-top: 1em;
  }

  .ui.secondary.menu .item {
    padding-left: 0;
    padding-right: 0;
  }
}

.mobile-container {
  .action-sheet {
    width: 100% !important;
  }

  .auth-wrapper {
    padding: 0;
    box-shadow: none;
    border: none;

    .ui.basic.segment {
      padding: 0;
    }
  }
}

/* */

.ui.centered.grid.auth-content {
  height: 100vh;
  // background: url('../img/welcome/1.jpg') no-repeat 50% 50%;
  // background-size: cover;
  background: linear-gradient(135deg, #f55c5c, #f55c5c 15%, #8f48bd);
  background-size: cover;
}

.ui.centered.grid.invitation-content {
  height: 100vh;
  background: url('../../img/welcome/bubbles.jpg') no-repeat 50% 50%;
  background-size: cover;
}

.sidebar-container {
  min-height: 100vh;
}

.ui.secondary.inverted.menu .link.item,
.ui.secondary.inverted.menu a.item {
  color: $white !important;
}

.ui.inverted.menu.top-nav {
  background-color: $primary;
  border-radius: 0;
  border-bottom: 0px solid $light-gray;
}

.ui.inverted.menu.top-nav .item,
.ui.inverted.menu.top-nav .item > a:not(.ui) {
  font-weight: bold;
}

.ui.inverted.menu.top-nav .header.item {
  background: #1e364f;
}

.ui.basic.segment.welcome-nav {
  background-color: $primary;
}

.ui.basic.segment.welcome-nav .ui.large.menu {
  background: transparent;
}

.ui[class*='very wide'].right.sidebar {
  width: 50%;
  background: $white;
  padding: 1em;
}

@media screen and (min-width: 768px) {
  .shadow {
    box-shadow: 0 0 30px 6px rgba(73, 72, 31, 0.1) !important;
    border: 0px $white solid !important;
  }

  .no-shadow {
    box-shadow: 0 0px 0px !important;
  }
}

.auth-form {
  &.auth-content {
    background: linear-gradient(135deg, #f55c5c, #f55c5c 15%, #c755d5);
    background-size: cover;
    // background: linear-gradient(#157afb, #1463c2);
  }
}

/* Buttons */
// .ui.button {
//   border-radius: 3px !important;
// }

// .ui.button:hover {
//   opacity: 0.8 !important;
//   background: $blue !important;
// }

.ui.button,
.ui.basic.button,
.ui.primary.button {
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  font-weight: bold;
  border-radius: 4px;
}

.ui.button {
  &.action-button {
    margin: 0.75em 0px;

    i.icon {
      background: none !important;
    }
  }
}

.ui.primary.button {
  background: $dark_blue;
  border-color: $dark_blue;
}

.ui.button.round,
.ui.basic.button.round {
  border-radius: 2rem !important;
}

.primary,
.blue {
  background: $dark_blue !important;
}

/* Sidebar */

.ui.sidebar {
  &.action-sheet {
    background: $white !important;

    .summary {
      background: $dark-blue;
    }
  }
}

.ui.sidebar.pane {
  text-align: left !important;
}
.ui.sidebar.pane.quarter {
  width: 25% !important;
}
.ui.sidebar.pane.half {
  width: 50% !important;
}
.ui.sidebar.pane.three-quarter {
  width: 75% !important;
}
.ui.sidebar.pane.full {
  width: 100% !important;
}

/*
  Cards
*/
.welcome .ui.cards {
  margin-bottom: 6em !important;
}

.ui.card.add-new {
  box-shadow: 0 0px 0px !important;
  border: 2px dashed #bdbdbd !important;
  text-align: center !important;
  cursor: pointer !important;
}

.ui.card.add-new:hover {
  border-color: #8a8888 !important;
}

.ui.card.add-new .header {
  font-size: 3em !important;
  color: #bdbdbd !important;
}

.ui.card.add-new:hover .header,
.ui.card.add-new:hover .description {
  color: #6e6e6e !important;
}

.ui.card.add-new .content {
  width: 100% !important;
  height: 100% !important;
  display: table !important;
}

.ui.card.add-new .content .center {
  display: table-cell !important;
  vertical-align: middle !important;
}

.ui.card.add-new .description {
  font-family: $default_font !important;
  font-size: 1.4em !important;
  font-weight: bold;
  color: #bdbdbd !important;
}

.ui.card.add-new .description:hover {
  color: #6e6e6e !important;
}

.ui.card.invited {
  opacity: 0.65 !important;
}

.ui.card.invited:hover {
  opacity: 1 !important;
}

.ui.card .content.center {
  margin: 0 auto;
}

.ui.card.center {
  text-align: center;
}

.ui.card .content.no-border {
  border-top: 0 !important;
}

.ui.card > .content > .header,
.ui.cards > .card > .content > .header {
  font-family: $default_font !important;
}

.ui.grid > .relaxed.row > .column,
.ui.relaxed.grid > .column:not(.row),
.ui.relaxed.grid > .row > .column {
  // padding-left: 0.5rem;
  // padding-right: 0.5rem;
}

.ui.cards > .card {
  &.simple {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }
}

/* Dimmer */

.ui.active.transition.visible.page.dimmer.action-sheet-cover {
  /* .ui.active.dimmer.action-sheet-cover { */
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.ui.active.transition.visible.page.dimmer {
  .action-sheet-header-container {
    padding-bottom: 0;

    h1 {
      margin-bottom: 0;
    }
  }
}

.ui.basic.segment {
  &.profile-cover {
    width: 100%;
    margin: 0 auto;
  }
}

// Table
.ui.table {
  &.no-border {
    tr {
      td {
        border-top: 0px !important;
      }
    }
  }
}

// Student
.ui.card.student {
  .ui.horizontal.relaxed.list > .item:not(:last-child) {
    padding-right: 0.5rem;
  }
}

.ui.segment.student {
  &.detail {
    padding: 0 !important;

    p {
      margin-bottom: 0;
    }

    .summary {
      background: $primary;
      padding: 0;

      h1 {
        color: $white;
      }
    }
  }
}

/* Media Queries */
/* Mobile */
@media only screen and (max-width: 767px) {
  [class*='mobile hidden'],
  [class*='tablet only']:not(.mobile),
  [class*='computer only']:not(.mobile),
  [class*='large screen only']:not(.mobile),
  [class*='widescreen only']:not(.mobile),
  [class*='or lower hidden'] {
    display: none !important;
  }

  .ui.sidebar.pane.quarter,
  .ui.sidebar.pane.half,
  .ui.sidebar.pane.three-quarter,
  .ui.sidebar.pane.full {
    width: 100% !important;
  }
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*='mobile only']:not(.tablet),
  [class*='tablet hidden'],
  [class*='computer only']:not(.tablet),
  [class*='large screen only']:not(.tablet),
  [class*='widescreen only']:not(.tablet),
  [class*='or lower hidden']:not(.mobile) {
    display: none !important;
  }

  .ui.sidebar.pane.quarter,
  .ui.sidebar.pane.half,
  .ui.sidebar.pane.three-quarter,
  .ui.sidebar.pane.full {
    width: 100% !important;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*='mobile only']:not(.computer),
  [class*='tablet only']:not(.computer),
  [class*='computer hidden'],
  [class*='large screen only']:not(.computer),
  [class*='widescreen only']:not(.computer),
  [class*='or lower hidden']:not(.tablet):not(.mobile) {
    display: none !important;
  }

  .ui.sidebar.pane.quarter,
  .ui.sidebar.pane.half,
  .ui.sidebar.pane.three-quarter,
  .ui.sidebar.pane.full {
    width: 75% !important;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*='mobile only']:not([class*='large screen']),
  [class*='tablet only']:not([class*='large screen']),
  [class*='computer only']:not([class*='large screen']),
  [class*='large screen hidden'],
  [class*='widescreen only']:not([class*='large screen']),
  [class*='or lower hidden']:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*='mobile only']:not([class*='widescreen']),
  [class*='tablet only']:not([class*='widescreen']),
  [class*='computer only']:not([class*='widescreen']),
  [class*='large screen only']:not([class*='widescreen']),
  [class*='widescreen hidden'],
  [class*='widescreen or lower hidden'] {
    display: none !important;
  }
}

// // Modal Override
// .ui.modal {
//   // position: static !important;
//   padding: 0.5em;
// }

.top-navigation {
  &.ui.menu {
    // border-width: 0;
    // box-shadow: none;

    .item {
      font-family: $default_font !important;
      position: unset;

      .input input {
        color: $black !important;
      }
    }

    .ui.button,
    .ui.basic.blue.button {
      font-weight: normal !important;
    }
  }
}

.top-nav {
  &.ui.menu {
    border-width: 0;
    box-shadow: none;

    .item {
      font-family: $default_font;
      font-size: 16px;
      font-weight: 700 !important;
      border-width: 0;
      position: unset;

      &.active {
        background: none !important;
        border-bottom: 2px solid $blue !important;
      }

      &.blue {
        background-color: $green;
        color: $white;
      }
    }
  }
}

.messages-wrapper {
  margin: 40px;
}

.side-modal .slide-pane__header {
  background: $primary;
  display: table;
  width: 100%;
}
.side-modal .slide-pane__content {
  padding: 24px 10px 24px 48px;
}
.side-modal .slide-pane__title-wrapper {
  margin: 0;
}

.side-modal .slide-pane__subtitle {
  margin: 0 0 20px 0;
}

// This is the close button
.side-modal .slide-pane__title {
  max-width: 100%;
  margin: 20px 20px 0 0;
}

.side-modal .slide-pane__close {
  display: none;
}

.setup-step {
  background: $primary !important;
  border-radius: 8px !important;
  min-height: 100%;

  .ui.steps {
    border: none;
    width: 100%;

    &.step {
      &.title {
        font-weight: normal;
      }
    }
  }

  .ui.vertical.steps .step {
    border: none;
    background: transparent;
  }

  .ui.vertical.steps {
    .step {
      color: $white;

      &.active {
        color: $white !important;
        background: $white !important;
        border-radius: 4px;
      }
    }
  }

  .ui.vertical.steps .active.step:after,
  .ui.steps .active.step:after {
    display: none;
  }
}

.setup-ribbon {
  // background: $white !important;
  margin-bottom: 0 !important;
}

/* Sematic UI Fixes */

// // Sematic UI Modal close icon fix
// .ui.modal > .close {
//   top: 1.0535rem;
//   right: 1rem;
//   color: rgba(0, 0, 0, 0.87);
// }

// Semantic Messages
.ui.message {
  text-align: left;

  &.major {
    font-size: 1.2em;
    font-weight: bold;
  }

  &.warning {
    color: $primary;
    background-color: $yellow;
    box-shadow: none;
  }

  &.success,
  &.positive {
    color: $white;
    background-color: $green;
    box-shadow: none;

    .header {
      color: $white;
    }
  }

  &.negative {
    color: $white;
    background-color: $red;
    box-shadow: none;

    .header {
      color: $white;
    }
  }
}

// Footer
.footer {
  color: $gray;
  font-size: 14px;

  p {
    font-size: 14px;
  }
}

.powered-by-moxit {
  cursor: pointer;

  span {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 400;
    color: $gray;
    margin: 0;

    .moxit-icon {
      fill: #ef434a;
      vertical-align: text-bottom;
      transform: translateY(1.5px);
      height: 20px;
      font-weight: 400;
    }
  }
}

// Search Facets

.search-facets {
  .ui.button {
    color: rgb(119, 119, 119) !important;
    background: rgb(247, 247, 247) !important;
    font-size: 13px;

    &:hover {
      background: rgb(231, 231, 231) !important;
      color: rgb(119, 119, 119) !important;
    }
  }
}
