.bstRow {
}

.bstRow:hover {
  background: rgba(0, 0, 50, 0.02);
}

.bstRow td {
  vertical-align: middle;
  cursor: pointer;
}

.header {
  background-color: #ebeff4;
  font-weight: bold;
  padding: 10px 5px;
}
