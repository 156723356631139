.add-new {
  border-style: dashed;
  border-color: #ccc;
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.firebase-emulator-warning {
  display: none !important;
}
