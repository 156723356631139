.listing-detail {
  margin-top: -15px;

  .hero {
    position: relative;
    height: 340px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    margin-top: -15px;

    &::before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: rgba(187, 171, 171, 0.25);
    }
    &.card {
      border: 0px;
    }

    .detail-title {
      color: white !important;
    }
  }

  .section-one {
    margin-top: -80px !important;
  }
}
