.dashboard {
  .pushable {
    & > .pusher {
      #content {
        margin-top: 5em;
      }

      &.mobile-container {
        #content {
          margin-top: 1em;

          .banner {
            background: $white !important;
            border-bottom: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .mobile-container {
    #content {
      margin-top: 1em;

      .banner {
        background: $white !important;
        border-bottom: none;
        box-shadow: none;
      }
    }
  }

  .setup-ribbon {
    background: $white !important;
    margin-bottom: 0 !important;
  }

  .banner {
    background: $white !important;
    border-bottom: 1px solid #f1f1f1 solid;
    margin-top: 0;
    @include box-shadow(2px, 0, 2px, rgba(0, 0, 0, 0.1));
  }
}
